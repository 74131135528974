import {token} from "./jwt";

export const endRide = async (rideId: string) => {
  const headers = {
    Authorization: token
  }
  const body = JSON.stringify({rideId})
  const response = await fetch('https://admin.dabeeb.co/admin/iot/stop-ride', {method: 'POST', headers, body})
  const data = await response.json()
  if (response.status !== 200) {
    throw data
  }
  return data
}
