export const lockVehicle = async (qrNumber: string) => {
  const body = JSON.stringify({qrNumber})
  const response = await fetch('https://admin.dabeeb.co/lock', {method: 'POST', body})
  const data = await response.json()
  if (response.status === 200) {
    return data
  } else {
    throw data
  }
}
