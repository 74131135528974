import React, {useCallback, useEffect, useRef, useState} from 'react';
import './App.css';
import {endRide} from "./services/endRide";
import {getActiveRides} from "./services/getActiveRides";
import {lockVehicle} from "./services/lockVehicle";
import {unlockVehicle} from "./services/unlockVehicle";


interface Ride {
  id: string
  rideNumber: string
  startDateTime: string
  userId: {
    name: string
    walletAmount: number
  }
  fareData: {
    timeFare: number
    unlockFees: number
  }
}

function App() {
  const [loading, setLoading] = useState(true)
  const [rides, setRides] = useState<Ride[]>()

  const vehiclesRef = useRef<any>()

  const fetchActiveRides = useCallback(async () => {
    try {
      const data = await getActiveRides()
      setRides(data)
    } catch (e) {
      console.log(e)
    }
    setLoading(false)
  }, [])

  useEffect(() => {
    fetchActiveRides()
  }, [fetchActiveRides])

  const handleEndClick = async (rideId: string) => {
    try {
      await endRide(rideId)
      fetchActiveRides()
    } catch (e) {
      alert('Error ending ride')
    }
  }

  const handleLockClick = async () => {
    try {
      const qrNumbers: string[] = vehiclesRef.current?.value?.split(',')
      const requests = qrNumbers.map(lockVehicle)
      await Promise.all(requests)
      alert('Success!')
    } catch (e) {
      alert('Error locking vehicles')
    }
  }

  const handleUnlockClick = async () => {
    try {
      const qrNumbers: string[] = vehiclesRef.current?.value?.split(',')
      const requests = qrNumbers.map(unlockVehicle)
      await Promise.all(requests)
      alert('Success!')
    } catch (e) {
      alert('Error locking vehicles')
    }
  }

  let ridesContent

  if (loading) {
    return <div className="root">
      Loading...
    </div>
  }

  if (!rides) {
    ridesContent = <div className="root">
      Error loading rides
    </div>
  } else if (rides.length === 0) {
    ridesContent = <div className="root">
      There are no active rides
    </div>
  } else {
    ridesContent = (
      <>
        <h1>Active Rides</h1>
        {
          rides.map(ride => {
            const walletAmount = ride.userId.walletAmount
            const unlockFee = ride.fareData.unlockFees
            const timeFee = ride.fareData.timeFare
            const startDate = new Date(ride.startDateTime)
            const [, startTime] = startDate.toLocaleString().split(',')
            const allowedTime = (walletAmount - unlockFee) / timeFee // allowed time in minutes
            const allowedTimeInMs = allowedTime * 60 * 1000
            const endDate = new Date(startDate.getTime() + allowedTimeInMs)
            const [, endTime] = endDate.toLocaleString().split(',')
            return (
              <div key={ride.id}>
                <ul>
                  <li>Ride Number: {ride.rideNumber}</li>
                  <li>Time Fee: {timeFee} SR</li>
                  <li>Unlock Fee: {unlockFee} SR</li>
                  <li>
                    User
                    <ul>
                      <li>Name: {ride.userId.name}</li>
                      <li>Wallet: {walletAmount} SR</li>
                    </ul>
                  </li>
                  <li>Start Time: {startTime}</li>
                  <li>Calculated End Time: {endTime || 'N/A'}</li>
                </ul>
                <button onClick={() => handleEndClick(ride.id)}>End Ride</button>
                <hr/>
              </div>
            )
          })
        }
      </>
    )
  }


  return (
    <div className="root">
      <h1>Lock/Unlock Vehicles</h1>
      <input ref={vehiclesRef as any}/>
      <button onClick={handleLockClick}>Lock</button>
      <button onClick={handleUnlockClick}>Unlock</button>
      <hr/>
      {ridesContent}
    </div>
  );
}

export default App;
